export function setupSelectedState(selectedClass, multiple = false) {
  const selectedClasses = Array.isArray(selectedClass)
    ? selectedClass
    : [selectedClass];
  if (multiple) {
    return {
      selectedIndexes: [],
      selectedValues: {},
      selectedClasses,

      selectedStateInit(callback = () => {}) {
        this.$watch("selectedIndexes", (indexes) => {
          this.options.forEach((o, i) => {
            if (indexes.includes(i)) {
              o.classList.add(...this.selectedClasses);
              o.setAttribute("data-selected", true);
            } else {
              o.classList.remove(...this.selectedClasses);
              o.removeAttribute("data-selected");
            }
          });

          this.$root.toggleAttribute("data-dirty", indexes.length > 0);
          callback(indexes);
          this.$dispatch("programmatic-input");
        });
      },
    };
  } else {
    return {
      selectedIndex: -1,
      selectedClasses,

      selectedStateInit(callback = () => {}) {
        this.$watch("selectedIndex", (index, oldIndex) => {
          this.options[oldIndex]?.classList.remove(...this.selectedClasses);
          this.options[oldIndex]?.removeAttribute("data-selected");
          this.options[index]?.classList.add(...this.selectedClasses);
          this.options[index]?.setAttribute("data-selected", true);

          this.$root.toggleAttribute("data-dirty", index !== -1);
          callback(index, oldIndex);
          this.$dispatch("programmatic-input");
        });
      },

      setSelectedOption() {
        if (this.activeIndex !== -1) this.selectedIndex = this.activeIndex;
        this.expanded = !this.expanded;
      },
    };
  }
}
