export function hasOptions() {
  return {
    groups: [],
    options: [],

    optionsInit(valueSetter) {
      // Find all options and add event listeners
      this.options = this.$el.querySelectorAll('[role="option"]');
      this.groups = this.$el.querySelectorAll('[role="optgroup"]');
      this.options.forEach((option, index) => {
        option.addEventListener("mouseover", () => (this.activeIndex = index));
        option.addEventListener("mouseout", () => (this.activeIndex = -1));
        option.addEventListener("click", () => this.onOptionClick());
        if (valueSetter) valueSetter(option, index);
      });
    },

    filterOptions() {
      this.options.forEach(
        (o) =>
          (o.hidden =
            !(
              this.query.length === 0 || o.getAttribute("data-value") === "new"
            ) &&
            o.textContent
              .trim()
              .toLowerCase()
              .indexOf(this.query.toLowerCase()) === -1),
      );
      this.groups.forEach(
        (g) =>
          (g.hidden =
            g.querySelectorAll('[role="option"]:not([hidden])').length === 0),
      );
    },
  };
}
