import { scrollIntoViewIfNeeded } from "../../lib";

const shift = {
  up: -1,
  down: 1,
};

export function setupActiveState(activeClass = "") {
  return {
    activeIndex: -1,
    activeClasses: Array.isArray(activeClass) ? activeClass : [activeClass],

    findNextActive(dir) {
      const up = dir === "up";
      if (!this.expanded) this.expanded = true;
      let newIndex = this.activeIndex;
      do {
        newIndex += shift[dir];
        // Break out of loop if there is no next visible option
        if ((up && newIndex <= -1) || newIndex === this.options.length) return;
      } while (this.options[newIndex].hidden || this.options.disabled);
      this.activeIndex = newIndex;
    },

    activeStateInit() {
      this.$watch("activeIndex", (index, oldIndex) => {
        if (index !== -1) {
          scrollIntoViewIfNeeded(
            this.options[index],
            this.$refs.scrollWrapper,
            ["bottom", "top"],
          );
        }

        this.options[oldIndex]?.classList.remove(...this.activeClasses);
        this.options[oldIndex]?.removeAttribute("data-active");
        this.options[index]?.classList.add(...this.activeClasses);
        this.options[index]?.setAttribute("data-active", true);
      });
    },
  };
}
