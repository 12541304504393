export default function dropdown({
  activeClass = "dropdown__item--active",
  openClass = "dropdown--open",
} = {}) {
  return {
    expanded: false,
    activeIndex: -1,
    openClasses: Array.isArray(openClass) ? openClass : [openClass],
    activeClasses: Array.isArray(activeClass) ? activeClass : [activeClass],

    init() {
      this.items = this.$el.querySelectorAll('[role="menuitem"]');
      this.items.forEach((item, index) => {
        item.addEventListener("mouseover", () => (this.activeIndex = index));
        item.addEventListener("mouseout", () => (this.activeIndex = -1));
      });
      this.$watch("expanded", () => !this.expanded && (this.activeIndex = -1));
      this.$watch("expanded", (expanded) => {
        expanded
          ? this.$el.classList.add(...this.openClasses)
          : this.$el.classList.remove(...this.openClasses);
      });
      this.$watch("activeIndex", (index, oldIndex) => {
        this.items[oldIndex]?.classList.remove(...this.activeClasses);
        this.items[index]?.classList.add(...this.activeClasses);
      });
    },

    wrapper: {
      ["@focusout"](e) {
        if (this.$el.contains(e.relatedTarget)) return;

        setTimeout(() => (this.expanded = false), 250);
      },
      ["@click.outside"]() {
        this.expanded = false;
      },
    },

    trigger: {
      ["@click"]() {
        this.expanded = !this.expanded;
      },
      ["@keyup.arrow-up.prevent"]() {
        if (!this.expanded) this.expanded = true;
        if (this.activeIndex === 0) return;

        this.activeIndex =
          this.activeIndex === -1
            ? this.items.length - 1
            : this.activeIndex - 1;
      },
      ["@keyup.arrow-down.prevent"]() {
        if (!this.expanded) this.expanded = true;
        if (this.activeIndex !== this.items.length - 1) this.activeIndex++;
      },
    },

    target: {
      ["x-show"]() {
        return this.expanded;
      },
      ["@keyup.arrow-up.prevent"]() {
        if (this.activeIndex === 0) return;

        this.activeIndex =
          this.activeIndex === -1
            ? this.items.length - 1
            : this.activeIndex - 1;
      },
      ["@keyup.arrow-down.prevent"]() {
        if (this.activeIndex !== this.items.length - 1) this.activeIndex++;
      },
      ["x-transition:enter"]() {
        return "transition ease-out duration-100";
      },
      ["x-transition:enter-start"]() {
        return "transform opacity-0 scale-95";
      },
      ["x-transition:enter-end"]() {
        return "transform opacity-100 scale-100";
      },
      ["x-transition:leave"]() {
        return "transition ease-in duration-75";
      },
      ["x-transition:leave-start"]() {
        return "transform opacity-100 scale-100";
      },
      ["x-transition:leave-end"]() {
        return "transform opacity-0 scale-95";
      },
    },
  };
}
