import { Turbo } from "@hotwired/turbo-rails";

export function scrollToId(id) {
  document.querySelector(id).scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
}

export function scrollIntoViewIfNeeded(
  element,
  container = document.querySelector("main"),
  axis = ["bottom"],
) {
  const { bottomInView, topInView } = checkInView(element, container);
  const scrollToBottom = axis.includes("bottom") && !bottomInView;
  const scrollToTop = axis.includes("top") && !topInView;
  if (!scrollToBottom && !scrollToTop) return;

  element.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
}

function checkInView(element, container) {
  const containerBox = container.getBoundingClientRect();

  const elementBox = element.getBoundingClientRect();

  const bottomInView = containerBox.bottom > elementBox.bottom;
  const topInView = containerBox.top < elementBox.top;
  return { bottomInView, topInView };
}

/**
 * Setup preservation of scroll position on Turbo visits.
 *
 * Use `data-turbo-preserve-scroll` on an element to enable this.
 */
export function enablePreserveScroll() {
  window.smartmonitor.scrollTop = 0;

  document.addEventListener("turbo:click", ({ target }) => {
    if (target.hasAttribute("data-turbo-preserve-scroll")) {
      window.smartmonitor.scrollTop = document.scrollingElement.scrollTop;
    }
  });

  document.addEventListener("turbo:submit-start", ({ target }) => {
    if (target.hasAttribute("data-turbo-preserve-scroll")) {
      window.smartmonitor.scrollTop = document.scrollingElement.scrollTop;
    }
  });

  document.addEventListener("turbo:before-render", () => {
    // Note: this is hacky and relies on a private API.
    // If this functionality breaks, this is probably the cause.
    if (window.smartmonitor.scrollTop) {
      Turbo.navigator.currentVisit.scrolled = true;
    }
  });

  document.addEventListener("turbo:render", () => {
    if (window.smartmonitor.scrollTop) {
      window.scrollTo(0, window.smartmonitor.scrollTop);
    }

    window.smartmonitor.scrollTop = 0;
  });
}

/**
 * Setup scroll after a turbo frame has loaded.
 *
 * The `data-turbo-frame-scroll` attr on the link will define where the frame scrolls to.
 * Use an option for `Element.scrollIntoView({block: })` as the value.
 */
export function enableFrameScroll() {
  window.smartmonitor.frameScoll = null;

  document.addEventListener("turbo:click", ({ target }) => {
    if (target.hasAttribute("data-turbo-frame-scroll")) {
      window.smartmonitor.frameScoll = target.getAttribute(
        "data-turbo-frame-scroll",
      );
    }
  });

  document.addEventListener("turbo:frame-load", ({ target }) => {
    if (window.smartmonitor.frameScoll) {
      target.scrollIntoView({ block: window.smartmonitor.frameScoll });
    }

    window.smartmonitor.frameScoll = null;
  });
}

/**
 * Scroll to far right of scroll element
 *
 * This function mostly meant to define a start position for scrolls
 */
export function scrollToRight(el) {
  if (!el) return;

  el.scrollTo({
    left: el.scrollWidth,
    behaviour: "instant",
  });
}
